import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { queueTypes } from '../../Utils';

const RankMatchesChart = ({ rankData }) => {
	const hasNoGames = rankData?.length === 0 || rankData?.every((rank) => rank.wins + rank.losses === 0);
	const data = rankData?.map((rank) => ({
		name: queueTypes[rank.queueType],
		value: rank.wins + rank.losses
	}));
	const COLORS = ['#255727', '#2a7d2d'];

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		);
	};

	return (
		<>
			{!hasNoGames ? (
				<div>
					<PieChart width={160} height={160}>
						<Pie
							dataKey="value"
							data={data}
							cx="50%"
							cy="50%"
							startAngle={90}
							endAngle={-270}
							outerRadius={75}
							labelLine={false}
							label={renderCustomizedLabel}
						>
							{data.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
						<Tooltip />
					</PieChart>
					<div style={{ textAlign: 'center', color: 'white', marginTop: '5px' }}>Ranked Games</div>
				</div>
			) : null}
		</>
	);
};

export default RankMatchesChart;
