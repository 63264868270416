import './Spinner.css';

const Spinner = ({ size = 50 }) => {
	return (
		<div className="spinner" style={{ width: size, height: size }}>
			<div className="spinner-inner"></div>
		</div>
	);
};

export default Spinner;
