import { BrowserRouter, Routes, Route } from 'react-router-dom';
// pages
import MainPage from './pages/MainPage';
import SummonerPage from './pages/SummonerPage';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route index element={<MainPage />} />
				<Route path="summoner/:region/:summonerName" element={<SummonerPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
