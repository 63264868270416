import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { regions, useLocalStorageState } from '../../Utils';
import './SearchBar.css';

const SearchBar = () => {
	const inputRef = useRef();
	const [inputValue, setInputValue] = useState('');
	const [regionValue, setRegionValue] = useLocalStorageState('region', 'EUW');

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const isFocused = location?.state?.focused;
		if (isFocused) {
			inputRef?.current?.focus();
		}
	}, [location]);

	const onSearchClicked = () => {
		if (inputValue) {
			const hasTag = inputValue.includes('#');
			const summoner = hasTag ? inputValue.replace(/#/g, '-') : inputValue + '-' + regionValue;
			navigate(`summoner/${regions[regionValue]}/${summoner}`);
		} else {
			inputRef?.current?.focus();
		}
	};

	const handleKeyUp = (e) => {
		if (e?.key === 'Enter' && inputValue) {
			const hasTag = inputValue.includes('#');
			const summoner = hasTag ? inputValue.replace(/#/g, '-') : inputValue + '-' + regionValue;
			navigate(`summoner/${regions[regionValue]}/${summoner}`);
		}
	};

	return (
		<div className="searchBar">
			<input
				ref={inputRef}
				type="text"
				placeholder={'Summoner #' + regionValue}
				spellCheck={false}
				value={inputValue}
				onChange={(e) => setInputValue(e.target.value)}
				onKeyUp={handleKeyUp}
			/>
			<select value={regionValue} onChange={(e) => setRegionValue(e.target.value)}>
				{Object.keys(regions).map((region) => (
					<option key={region} value={region}>
						{region}
					</option>
				))}
			</select>
			<div className="searchButton">
				<img
					src={process.env.PUBLIC_URL + '/images/search.png'}
					alt="search"
					className={inputValue ? '' : 'disabledIcon'}
					onClick={onSearchClicked}
				/>
			</div>
		</div>
	);
};

export default SearchBar;
