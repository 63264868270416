import { useState, useEffect, useRef } from 'react';

/**
 * Custom hook for working with local storage
 * @param {String} key The key to set in localStorage for this value
 * @param {Object} defaultValue The value to use if it is not already in localStorage
 * @param {{serialize: Function, deserialize: Function}} options The serialize and deserialize functions to use (defaults to JSON.stringify and JSON.parse respectively)
 */
export const useLocalStorageState = (
	key,
	defaultValue = '',
	{ serialize = JSON.stringify, deserialize = JSON.parse } = {}
) => {
	const [state, setState] = useState(() => {
		const valueInLocalStorage = window.localStorage.getItem(key);
		if (valueInLocalStorage) {
			return deserialize(valueInLocalStorage);
		}
		return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
	});

	const prevKeyRef = useRef(key);

	useEffect(() => {
		const prevKey = prevKeyRef.current;
		if (prevKey !== key) {
			window.localStorage.removeItem(prevKey);
		}
		prevKeyRef.current = key;
		window.localStorage.setItem(key, serialize(state));
	}, [key, state, serialize]);

	return [state, setState];
};

// URLs
// =================================================================================================================================================
export const CDN_VERSIONS_URL = 'https://ddragon.leagueoflegends.com/api/versions.json';
export const BASE_CDN_URL = 'https://ddragon.leagueoflegends.com/cdn/';
export const CHAMP_IMG_URL = 'https://ddragon.leagueoflegends.com/cdn/14.8.1/img/champion/';
export const SUMMONER_IMG_URL = 'https://ddragon.leagueoflegends.com/cdn/14.8.1/img/spell/';
export const RUNE_IMG_URL = 'https://raw.communitydragon.org/latest/plugins/rcp-be-lol-game-data/global/default/v1/';
export const BASE_RANK_URL =
	'https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-shared-components/global/default/';
export const DEFAULT_ITEM_URL =
	'https://raw.communitydragon.org/latest/plugins/rcp-be-lol-game-data/global/default/assets/items/icons2d/gp_ui_placeholder.png';

// Mappings
// =================================================================================================================================================
export const regions = {
	NA: 'na1',
	EUW: 'euw1',
	EUNE: 'eun1',
	KR: 'kr',
	BR: 'br1',
	JP: 'jp1',
	RU: 'ru',
	OCE: 'oc1',
	TR: 'tr1',
	LAN: 'la1',
	LAS: 'la2',
	PH: 'ph2',
	SG: 'sg2',
	TH: 'th2',
	TW: 'tw2',
	VN: 'vn2'
};

export const queueTypes = {
	RANKED_SOLO_5x5: 'Ranked Solo',
	RANKED_FLEX_SR: 'Ranked Flex'
};

export const rankImages = {
	UNRANKED: BASE_RANK_URL + 'unranked.png',
	IRON: BASE_RANK_URL + 'iron.png',
	BRONZE: BASE_RANK_URL + 'bronze.png',
	SILVER: BASE_RANK_URL + 'silver.png',
	GOLD: BASE_RANK_URL + 'gold.png',
	PLATINUM: BASE_RANK_URL + 'platinum.png',
	EMERALD: BASE_RANK_URL + 'emerald.png',
	DIAMOND: BASE_RANK_URL + 'diamond.png',
	MASTER: BASE_RANK_URL + 'master.png',
	GRANDMASTER: BASE_RANK_URL + 'grandmaster.png',
	CHALLENGER: BASE_RANK_URL + 'challenger.png'
};

export const tiersNoRanks = ['UNRANKED', 'MASTER', 'GRANDMASTER', 'CHALLENGER'];
export const positions = ['TOP', 'JUNGLE', 'MIDDLE', 'BOTTOM', 'UTILITY', 'Invalid'];

export const gameModes = {
	420: 'Ranked Solo',
	430: 'Normal Draft',
	440: 'Ranked Flex',
	450: 'ARAM',
	490: 'Normal Quickplay',
	700: 'Clash', // summoner's rift
	720: 'Clash' // aram
};

// Functions
// =================================================================================================================================================
export const capitalizeWord = (word) => {
	if (word) {
		word = word.toLowerCase();
		return word.charAt(0).toUpperCase() + word.slice(1);
	}
	return '';
};

export const getDivision = (tier, rank) => {
	const removeRank = tiersNoRanks.includes(tier);
	if (removeRank) {
		return capitalizeWord(tier);
	}
	return capitalizeWord(tier) + ' ' + rank;
};

export const calculateWinrate = (wins, losses) => {
	if (wins + losses === 0) return 0;
	return Math.round((wins / (wins + losses)) * 100);
};

export const getTimeAgo = (timestamp) => {
	const now = new Date();
	const delta = now - timestamp;

	const seconds = Math.floor(delta / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const months = Math.floor(days / 30);
	const years = Math.floor(months / 12);

	if (years > 0) {
		if (years === 1) {
			return '1 year ago';
		} else {
			return `${years} years ago`;
		}
	} else if (months > 0) {
		if (months === 1) {
			return '1 month ago';
		} else {
			return `${months} months ago`;
		}
	} else if (days > 0) {
		if (days === 1) {
			return '1 day ago';
		} else {
			return `${days} days ago`;
		}
	} else if (hours > 0) {
		if (hours === 1) {
			return '1 hour ago';
		} else {
			return `${hours} hours ago`;
		}
	} else if (minutes > 0) {
		if (minutes === 1) {
			return '1 minute ago';
		} else {
			return `${minutes} minutes ago`;
		}
	} else {
		if (seconds <= 10) {
			return 'just now';
		} else {
			return `${seconds} seconds ago`;
		}
	}
};

export const formatThousands = (number) => {
	if (number >= 1000 && number < 1000000) {
		const formatted = (number / 1000).toFixed(1);
		return formatted.endsWith('.0') ? formatted.slice(0, -2) + 'K' : formatted + 'K';
	} else {
		return number.toString();
	}
};

export const formatTime = (seconds) => {
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = seconds % 60;
	return `${minutes}m ${remainingSeconds}s`;
};

export const calculateKP = (participants, player) => {
	const teamKills = participants.reduce((acc, participant) => {
		return acc + participant?.kills;
	}, 0);

	return teamKills > 0 ? Math.round(((player?.kills + player?.assists) / teamKills) * 100) : 0;
};

export const calculateTeamAverageKP = (participants) => {
	const totalKP = participants.reduce((acc, player) => acc + calculateKP(participants, player), 0);
	const teamAverageKP = totalKP / 5;
	return Math.round(teamAverageKP);
};

export const calculateKDA = (player) => {
	return Math.round(((player?.kills + player?.assists) / (player?.deaths || 1)) * 100) / 100;
};

export const calculateTeamAverageKDA = (participants) => {
	const totalKP = participants.reduce((acc, player) => acc + calculateKDA(player), 0);
	const teamAverageKP = totalKP / 5;
	return Math.round(teamAverageKP);
};

export const calculateScore = (kda, averageKDA, kp, averageKP) => {
	const kdaScore = (kda - averageKDA) * 10 * 0.6;
	const kpScore = (kp - averageKP) * 0.4;
	return Math.round((50 + kdaScore || 0 + kpScore || 0) * 100) / 100;
};

export const aggregatePlayersStats = (participants) => {
	const aggregatedStats = [];
	const team1 = participants?.slice(0, 5);
	const team2 = participants?.slice(-5);

	const team1AverageKP = calculateTeamAverageKP(team1);
	const team1AverageKDA = calculateTeamAverageKDA(team1);
	const team2AverageKP = calculateTeamAverageKP(team2);
	const team2AverageKDA = calculateTeamAverageKDA(team2);

	team1.forEach((player) => {
		const kda = calculateKDA(player);
		const kp = calculateKP(team1, player);
		const score = calculateScore(kda, team1AverageKDA, kp, team1AverageKP);

		aggregatedStats.push({
			puuid: player.puuid,
			teamId: player.teamId,
			kda,
			kp,
			score
		});
	});

	team2.forEach((player) => {
		const kda = calculateKDA(player);
		const kp = calculateKP(team2, player);
		const score = calculateScore(kda, team2AverageKDA, kp, team2AverageKP);

		aggregatedStats.push({
			puuid: player.puuid,
			teamId: player.teamId,
			kda,
			kp,
			score
		});
	});

	return aggregatedStats;
};

export const getMaxValueForKey = (array, key) => {
	if (!Array.isArray(array) || array.length === 0) {
		return undefined;
	}

	return array.reduce((maxValue, obj) => {
		const value = obj[key];
		return value > maxValue ? value : maxValue;
	}, array[0][key]);
};

export const formatNumberWithThousands = (number) => {
	return new Intl.NumberFormat('en-US').format(number);
};
