import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
	return (
		<div className="mainHeader">
			<Link to="/">
				<div className="logoContainer">
					<img src={process.env.PUBLIC_URL + '/images/logo-64.png'} alt="logo" />
					<h2>Sigma LoL</h2>
				</div>
			</Link>
			<div className="searchIcon">
				<Link to="/" state={{ focused: true }}>
					<img src={process.env.PUBLIC_URL + '/images/search-32.png'} alt="search" />
				</Link>
			</div>
		</div>
	);
};

export default Header;
