import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const MVPChart = ({ sigmas, alphas }) => {
	const data = [
		{ name: 'Sigma games', value: sigmas },
		{ name: 'Alpha games', value: alphas }
	];
	const COLORS = ['#8a0000', '#003c96'];

	return (
		<>
			{sigmas || alphas ? (
				<div>
					<PieChart width={160} height={160}>
						<Pie
							dataKey="value"
							data={data}
							cx="50%"
							cy="50%"
							startAngle={90}
							endAngle={-270}
							outerRadius={75}
							labelLine={false}
						>
							{data.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
						<Tooltip />
					</PieChart>
					<div style={{ textAlign: 'center', color: 'white', marginTop: '5px' }}>MVP Distribution</div>
				</div>
			) : null}
		</>
	);
};

export default MVPChart;
