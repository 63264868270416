import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import {
	gameModes,
	getTimeAgo,
	CHAMP_IMG_URL,
	SUMMONER_IMG_URL,
	RUNE_IMG_URL,
	DEFAULT_ITEM_URL,
	formatThousands,
	formatTime,
	aggregatePlayersStats,
	getMaxValueForKey,
	positions,
	calculateKP,
	getDivision
} from '../../Utils.js';
import { getPlayerRanks } from '../../API';
import './MatchCard.css';

const MatchCard = ({
	index,
	match,
	puuid,
	region,
	rankData,
	champData,
	spellData,
	runeData,
	BASE_IMG_URL,
	updateSigma,
	updateAlpha
}) => {
	const [showDetails, setShowDetails] = useState(false);
	const [playerRanks, setPlayerRanks] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const owner = useMemo(
		() => match?.participants?.find((participant) => participant?.puuid === puuid),
		[match?.participants, puuid]
	);
	const playersStats = useMemo(() => aggregatePlayersStats(match?.participants), [match?.participants]);
	const ownerStats = useMemo(
		() => playersStats?.find((player) => player?.puuid === owner?.puuid),
		[playersStats, owner]
	);

	const isSigma = useMemo(() => {
		if (owner?.win) {
			const isTeam1 = owner?.teamId === 100;
			const ownerScore = ownerStats?.score;

			let maxScore;
			if (isTeam1) {
				maxScore = getMaxValueForKey(Object.values(playersStats).slice(0, 5), 'score');
			} else {
				maxScore = getMaxValueForKey(Object.values(playersStats).slice(-5), 'score');
			}

			return ownerScore === maxScore;
		}
		return false;
	}, [owner, ownerStats, playersStats]);

	const isAlpha = useMemo(() => {
		if (!owner?.win) {
			const isTeam1 = owner?.teamId === 100;
			const ownerScore = ownerStats?.score;

			let maxScore;
			if (isTeam1) {
				maxScore = getMaxValueForKey(Object.values(playersStats).slice(0, 5), 'score');
			} else {
				maxScore = getMaxValueForKey(Object.values(playersStats).slice(-5), 'score');
			}

			return ownerScore === maxScore;
		}
		return false;
	}, [owner, ownerStats, playersStats]);

	useEffect(() => {
		if (isSigma) {
			updateSigma(index);
		}

		if (isAlpha) {
			updateAlpha(index);
		}
	}, [index, isSigma, isAlpha, updateSigma, updateAlpha]);

	const toggleDetails = async () => {
		setShowDetails(!showDetails);

		if (!showDetails && !playerRanks) {
			const participants = match?.participants
				?.filter((participant) => participant?.puuid !== owner?.puuid)
				?.map((p) => p?.summonerId);

			setIsLoading(true);
			const response = await getPlayerRanks(region, participants);
			setIsLoading(false);
			setPlayerRanks(response);
		}
	};

	const getChamp = (champId) => {
		const id = champId + '';
		for (const [key, val] of Object.entries(champData)) {
			if (val?.key === id) {
				return key;
			}
		}
		return 'Draven';
	};

	const getSpell = (spellId) => {
		const id = spellId + '';
		for (const [key, val] of Object.entries(spellData)) {
			if (val?.key === id) {
				return key;
			}
		}
		return 'SummonerFlash';
	};

	const getRune = (rune) => {
		let icon;
		const id = rune?.style;
		const desc = rune?.description;
		const mainId = rune?.selections[0]?.perk;

		for (const runeVal of Object.values(runeData)) {
			if (runeVal?.id === id) {
				if (desc === 'subStyle') {
					icon = runeVal?.icon?.toLowerCase();
					return icon;
				}
				for (const val of Object.values(runeVal?.slots[0]?.runes)) {
					if (val?.id === mainId) {
						icon = val?.icon?.toLowerCase();
						return icon;
					}
				}
			}
		}
		return 'perk-images/styles/runesicon.png';
	};

	const getKDAScore = (kills, deaths, assists) => {
		const kda = deaths > 0 ? ((kills + assists) / deaths).toFixed(2) : 'Perfect';
		const kdaType = kda === 'Perfect' || kda >= 5 ? 'greatKda' : kda >= 3 ? 'goodKda' : '';

		return (
			<div className="kdaScore">
				<span className={kdaType}>{kda} </span>KDA
			</div>
		);
	};

	const getKP = (participant) => {
		let kp;
		const isOwner = participant?.puuid === owner?.puuid;

		if (participant && !isOwner) {
			const isTeam1 = participant?.teamId === 100;
			const participants = isTeam1 ? match?.participants?.slice(0, 5) : match?.participants?.slice(-5);
			kp = calculateKP(participants, participant);
		} else {
			kp = ownerStats?.kp;
		}
		const kpType = kp >= 60 ? 'greatKP' : '';

		return (
			<div className="kdaScore" title="Kill Participation">
				<span className={kpType}>{kp}% </span>KP
			</div>
		);
	};

	const getCS = (cs, duration) => {
		const mins = Math.floor(duration / 60);
		const cpm = (cs / mins).toFixed(1);
		const csType = cpm >= 10 ? 'greatCS' : '';

		return (
			<div className="secondaryScore" title="Creep Score (CS per minute)">
				<span className={csType}>{cs} </span>CS {`(${cpm})`}
			</div>
		);
	};

	const getVision = (vision) => {
		const visionType = vision >= 100 ? 'greatVision' : '';

		return (
			<div className="secondaryScore">
				<span className={visionType}>{vision} </span>Vision Score
			</div>
		);
	};

	const getAdditionalDetails = () => {
		const isTeam1 = owner?.teamId === 100;
		const team1 = match?.participants?.slice(0, 5).sort((a, b) => {
			return positions.indexOf(a.individualPosition) - positions.indexOf(b.individualPosition);
		});
		const team2 = match?.participants?.slice(-5).sort((a, b) => {
			return positions.indexOf(a.individualPosition) - positions.indexOf(b.individualPosition);
		});

		return (
			<>
				{isTeam1 ? (
					<>
						<span className="matchResult">
							{team1[0]?.win ? (
								<span className="victory">Victory</span>
							) : (
								<span className="defeat">Defeat</span>
							)}
							<span>{team1[0]?.teamId === 100 ? ' (Blue Side)' : ' (Red Side)'}</span>
						</span>
						{team1.map((participant) => renderParticipantData(participant))}
						<span className="matchResult">
							{team2[0]?.win ? (
								<span className="victory">Victory</span>
							) : (
								<span className="defeat">Defeat</span>
							)}
							<span>{team2[0]?.teamId === 100 ? ' (Blue Side)' : ' (Red Side)'}</span>
						</span>
						{team2.map((participant) => renderParticipantData(participant))}
					</>
				) : (
					<>
						<span className="matchResult">
							{team2[0]?.win ? (
								<span className="victory">Victory</span>
							) : (
								<span className="defeat">Defeat</span>
							)}
							<span>{team2[0]?.teamId === 100 ? ' (Blue Side)' : ' (Red Side)'}</span>
						</span>
						{team2.map((participant) => renderParticipantData(participant))}
						<span className="matchResult">
							{team1[0]?.win ? (
								<span className="victory">Victory</span>
							) : (
								<span className="defeat">Defeat</span>
							)}
							<span>{team1[0]?.teamId === 100 ? ' (Blue Side)' : ' (Red Side)'}</span>
						</span>
						{team1.map((participant) => renderParticipantData(participant))}
					</>
				)}
			</>
		);
	};

	const getRank = (participant) => {
		let rank = { tier: 'UNRANKED' };
		const isOwner = participant?.puuid === owner?.puuid;
		const mode = match?.queueId === 440 ? 'RANKED_FLEX_SR' : 'RANKED_SOLO_5x5';
		const otherMode = match?.queueId === 440 ? 'RANKED_SOLO_5x5' : 'RANKED_FLEX_SR';

		if (isOwner) {
			rank =
				rankData?.find((rank) => rank?.queueType === mode) ||
				rankData?.find((rank) => rank?.queueType === otherMode) ||
				rank;
		} else {
			const rankedData = playerRanks[participant?.summonerId];
			rank =
				rankedData?.find((rank) => rank?.queueType === mode) ||
				rankedData?.find((rank) => rank?.queueType === otherMode) ||
				rank;
		}
		return getDivision(rank?.tier, rank?.rank);
	};

	const getMVP = (participant) => {
		let sigma, alpha;
		const isOwner = participant?.puuid === owner?.puuid;

		if (isOwner) {
			sigma = participant?.win && isSigma;
			alpha = !participant?.win && isAlpha;
		} else {
			const isTeam1 = participant?.teamId === 100;
			const playerScore = playersStats?.find((player) => player?.puuid === participant?.puuid)?.score;

			let maxScore;
			if (isTeam1) {
				maxScore = getMaxValueForKey(Object.values(playersStats).slice(0, 5), 'score');
			} else {
				maxScore = getMaxValueForKey(Object.values(playersStats).slice(-5), 'score');
			}

			if (participant?.win) {
				sigma = playerScore === maxScore;
			} else {
				alpha = playerScore === maxScore;
			}
		}

		return sigma ? (
			<span className="sigma" title="Great performance with a win">
				Sigma
			</span>
		) : alpha ? (
			<span className="alpha" title="Great performance with a loss">
				Alpha
			</span>
		) : null;
	};

	const renderParticipantData = (participant) => {
		const isOwner = participant?.puuid === owner?.puuid;
		const participantClass =
			isOwner && participant?.win
				? 'ownerWin'
				: isOwner && !participant?.win
				? 'ownerLose'
				: !isOwner && participant?.win
				? 'participantWin'
				: 'participantLose';

		return (
			<div key={participant?.puuid} className={`participantData ${participantClass}`}>
				<div className="matchSecondRow">
					<div className="mainIcons">
						<div className="ownerChampion">
							<img
								src={`${CHAMP_IMG_URL + getChamp(participant?.championId) + '.png'}`}
								alt="Champion"
								className="mainChamp"
							/>
							<div className="champLevel">{participant?.champLevel}</div>
						</div>
						<div className="spellContainer">
							<img
								src={`${SUMMONER_IMG_URL + getSpell(participant?.summoner1Id) + '.png'}`}
								alt="Summoner 1"
								className="spell"
							/>
							<img
								src={`${SUMMONER_IMG_URL + getSpell(participant?.summoner2Id) + '.png'}`}
								alt="Summoner 2"
								className="spell"
							/>
						</div>
						<div className="spellContainer">
							<img
								src={`${RUNE_IMG_URL + getRune(participant?.perks?.styles[0])}`}
								alt="Primary rune"
								className="spell"
							/>
							<div className="spell">
								<img
									src={`${RUNE_IMG_URL + getRune(participant?.perks?.styles[1])}`}
									alt="Secondary rune"
									className="spellSecondary"
								/>
							</div>
						</div>
						<span className="participantInfo">
							<span className="participantName" title={participant.riotIdGameName}>
								{owner?.puuid === participant?.puuid ? (
									participant.riotIdGameName
								) : (
									<Link
										to={`/summoner/${region}/${participant.riotIdGameName}-${participant.riotIdTagline}`}
									>
										{participant.riotIdGameName}
									</Link>
								)}
							</span>
							<span className="participantElo">{getRank(participant)}</span>
							<span>{getMVP(participant)}</span>
						</span>
					</div>
					<div className="primaryStats">
						<div className="kda">
							<span className="kills">{participant?.kills}</span>/
							<span className="deaths">{participant?.deaths}</span>/
							<span className="assists">{participant?.assists}</span>
						</div>
						{getKDAScore(participant?.kills, participant?.deaths, participant?.assists)}
						{getKP(participant)}
					</div>
					<div className="secondaryStats">
						{getCS(participant?.totalMinionsKilled, match?.gameDuration)}
						{getVision(participant?.visionScore)}
						<div className="secondaryScore">
							<span>{participant?.visionWardsBoughtInGame} </span>Control Wards
						</div>
						<div className="secondaryScore">
							<span>{formatThousands(participant?.goldEarned)} </span>Gold
						</div>
					</div>
					<div className="items">
						<img
							src={
								participant?.item0
									? BASE_IMG_URL + 'item/' + participant?.item0 + '.png'
									: DEFAULT_ITEM_URL
							}
							alt="Item 1"
						/>
						<img
							src={
								participant?.item1
									? BASE_IMG_URL + 'item/' + participant?.item1 + '.png'
									: DEFAULT_ITEM_URL
							}
							alt="Item 2"
						/>
						<img
							src={
								participant?.item2
									? BASE_IMG_URL + 'item/' + participant?.item2 + '.png'
									: DEFAULT_ITEM_URL
							}
							alt="Item 3"
						/>
						<img
							src={
								participant?.item3
									? BASE_IMG_URL + 'item/' + participant?.item3 + '.png'
									: DEFAULT_ITEM_URL
							}
							alt="Item 4"
						/>
						<img
							src={
								participant?.item4
									? BASE_IMG_URL + 'item/' + participant?.item4 + '.png'
									: DEFAULT_ITEM_URL
							}
							alt="Item 5"
						/>
						<img
							src={
								participant?.item5
									? BASE_IMG_URL + 'item/' + participant?.item5 + '.png'
									: DEFAULT_ITEM_URL
							}
							alt="Item 6"
						/>
						<img
							src={
								participant?.item6
									? BASE_IMG_URL + 'item/' + participant?.item6 + '.png'
									: DEFAULT_ITEM_URL
							}
							alt="Item 7"
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className={`matchCard ${owner?.win ? 'winCard' : 'lossCard'}`}>
				<div className="cardGroup">
					<div className="cardContent">
						<div className="matchFirstRow">
							<span className="result">
								<span>{owner?.win ? 'WIN' : 'LOSS'}</span>&bull;
								<span className="gameDuration">{formatTime(match?.gameDuration)}</span>
							</span>
							<span>
								<span className="queueType">{gameModes[match?.queueId] || 'Featured'}</span>
								{isSigma ? (
									<>
										&bull;
										<span className="sigma" title="Great performance with a win">
											Sigma
										</span>
									</>
								) : isAlpha ? (
									<>
										&bull;
										<span className="alpha" title="Great performance with a loss">
											Alpha
										</span>
									</>
								) : null}
							</span>
							<span className="timeAgo">{getTimeAgo(match?.gameEndTimestamp)}</span>
						</div>
						<div className="matchSecondRow">
							<div className="mainIcons">
								<div className="ownerChampion">
									<img
										src={`${CHAMP_IMG_URL + getChamp(owner?.championId) + '.png'}`}
										alt="Champion"
										className="mainChamp"
									/>
									<div className="champLevel">{owner?.champLevel}</div>
								</div>
								<div className="spellContainer">
									<img
										src={`${SUMMONER_IMG_URL + getSpell(owner?.summoner1Id) + '.png'}`}
										alt="Summoner 1"
										className="spell"
									/>
									<img
										src={`${SUMMONER_IMG_URL + getSpell(owner?.summoner2Id) + '.png'}`}
										alt="Summoner 2"
										className="spell"
									/>
								</div>
								<div className="spellContainer">
									<img
										src={`${RUNE_IMG_URL + getRune(owner?.perks?.styles[0])}`}
										alt="Primary rune"
										className="spell"
									/>
									<div className="spell">
										<img
											src={`${RUNE_IMG_URL + getRune(owner?.perks?.styles[1])}`}
											alt="Secondary rune"
											className="spellSecondary"
										/>
									</div>
								</div>
							</div>
							<div className="primaryStats">
								<div className="kda">
									<span className="kills">{owner?.kills}</span>/
									<span className="deaths">{owner?.deaths}</span>/
									<span className="assists">{owner?.assists}</span>
								</div>
								{getKDAScore(owner?.kills, owner?.deaths, owner?.assists)}
								{getKP()}
							</div>
							<div className="secondaryStats">
								{getCS(owner?.totalMinionsKilled, match?.gameDuration)}
								{getVision(owner?.visionScore)}
								<div className="secondaryScore">
									<span>{owner?.visionWardsBoughtInGame} </span>Control Wards
								</div>
								<div className="secondaryScore">
									<span>{formatThousands(owner?.goldEarned)} </span>Gold
								</div>
							</div>
							<div className="items">
								<img
									src={
										owner?.item0 ? BASE_IMG_URL + 'item/' + owner?.item0 + '.png' : DEFAULT_ITEM_URL
									}
									alt="Item 1"
								/>
								<img
									src={
										owner?.item1 ? BASE_IMG_URL + 'item/' + owner?.item1 + '.png' : DEFAULT_ITEM_URL
									}
									alt="Item 2"
								/>
								<img
									src={
										owner?.item2 ? BASE_IMG_URL + 'item/' + owner?.item2 + '.png' : DEFAULT_ITEM_URL
									}
									alt="Item 3"
								/>
								<img
									src={
										owner?.item3 ? BASE_IMG_URL + 'item/' + owner?.item3 + '.png' : DEFAULT_ITEM_URL
									}
									alt="Item 4"
								/>
								<img
									src={
										owner?.item4 ? BASE_IMG_URL + 'item/' + owner?.item4 + '.png' : DEFAULT_ITEM_URL
									}
									alt="Item 5"
								/>
								<img
									src={
										owner?.item5 ? BASE_IMG_URL + 'item/' + owner?.item5 + '.png' : DEFAULT_ITEM_URL
									}
									alt="Item 6"
								/>
								<img
									src={
										owner?.item6 ? BASE_IMG_URL + 'item/' + owner?.item6 + '.png' : DEFAULT_ITEM_URL
									}
									alt="Item 7"
								/>
							</div>
						</div>
					</div>
					<div className="toggleDetails" onClick={toggleDetails}>
						{showDetails ? <span>&#9650;</span> : <span>&#9660;</span>}
					</div>
				</div>
			</div>
			{showDetails ? (
				isLoading ? (
					<Spinner />
				) : (
					<div className="additionalDetails">{getAdditionalDetails()}</div>
				)
			) : null}
		</>
	);
};

export default MatchCard;
