import { CDN_VERSIONS_URL } from './Utils';

export const getCDNVersion = async () => {
	const cdnResponse = await fetch(CDN_VERSIONS_URL);
	return await cdnResponse.json();
};

export const getCDNData = async (URL) => {
	const cdnResponse = await fetch(URL);
	return await cdnResponse.json();
};

const URL = 'https://wb07wpud50.execute-api.eu-central-1.amazonaws.com/default';

export const getSummonerData = async (region, summonerName) => {
	const res = await fetch(`${URL}/getSummonerData?region=${region}&name=${summonerName}`);
	return await res.json();
};

export const getPlayerRanks = async (region, participants) => {
	const res = await fetch(`${URL}/getPlayerRanks`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ region, participants })
	});
	return await res.json();
};

export const getMoreMatches = async (puuid, pageStart) => {
	const res = await fetch(`${URL}/getMoreMatches?puuid=${puuid}&pageStart=${pageStart}`);
	return await res.json();
};
