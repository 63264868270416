import React from 'react';
import { RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts';
import { calculateWinrate } from '../../Utils';

const RankWinrateChart = ({ rankData }) => {
	const totalWinrates = rankData?.reduce((sum, rank) => sum + calculateWinrate(rank.wins, rank.losses), 0);
	const averageWinrate = totalWinrates / rankData?.length || 0;

	const data = [{ name: 'Winrate', value: averageWinrate, fill: '#2163ff' }];

	return (
		<div>
			<div style={{ position: 'relative', width: '160px', height: '160px' }}>
				<RadialBarChart
					width={160}
					height={160}
					cx="50%"
					cy="50%"
					innerRadius={70}
					outerRadius={70}
					barSize={10}
					data={data}
					startAngle={90}
					endAngle={-270}
				>
					<PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
					<RadialBar background clockWise dataKey="value" />
				</RadialBarChart>
				<div
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						textAlign: 'center'
					}}
				>
					<div style={{ fontSize: '24px', fontWeight: 'bold' }}>{averageWinrate.toFixed(1)}%</div>
				</div>
			</div>
			<div style={{ textAlign: 'center', color: 'white', marginTop: '5px' }}>Ranked Winrate</div>
		</div>
	);
};

export default RankWinrateChart;
