import Header from '../components/Header/Header';
import SearchBar from '../components/SearchBar/SearchBar';
import '../styles/MainPage.css';

const MainPage = () => {
	return (
		<div className="page">
			<Header />
			<div className="content">
				<div className="title">
					<img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" />
					<h1>Sigma LoL</h1>
				</div>
				<div className="description">
					<span>League of Legends stats for sigma players</span>
				</div>
				<SearchBar />
			</div>
		</div>
	);
};

export default MainPage;
